import React from "react"
import { Link, graphql } from "gatsby"
import Img from "gatsby-image"

import Layout from "../components/Layout"
import Header from "../components/Header"
import SEO from "../components/seo"
import Newsletter from "../components/Newsletter"
import Article from "../components/Article"

const BlogIndex = ({ data }) => {
  const posts = data.allMdx.edges

  return (
    <Layout>
      <SEO title="Leituras por Flavia Nunes" />

      <Header
        title="Leituras, por Flavia Nunes"
        subtitle="Resenhas literárias, indicações, listas e afins"
      />

     

    

      <section className="article-container">
        {posts.map(({ node }) => {
          const post = {
            ...node.frontmatter,
            ...node.fields,
            readingTime: node.timeToRead,
            image: node.frontmatter.cover.childImageSharp.fluid,
          }
          return (
            <div key={node.fields.slug}>
              <Article {...post} />
            </div>
          )
        })}
        <Newsletter />
        
      </section>
      <Link to="/posts" className="button all-posts">
          Todas as postagens
        </Link>
    </Layout>
  )
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMdx(sort: { fields: [frontmatter___date], order: DESC }, limit: 5) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "DD/MM/YYYY")
            title
            description
            cover {
              childImageSharp {
                fluid(maxWidth: 820, quality: 100) {
                  ...GatsbyImageSharpFluid_noBase64
                }
              }
            }
          }
          timeToRead
        }
      }
    }
  }
`
